@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@1,600&display=swap');

@font-face {
    font-family: 'Gotham Bold';
    src: url('../assets/fonts/Gotham/Gotham-Bold.eot');
    src: url('../assets/fonts/Gotham/Gotham-Bold.eot?#iefix')
            format('embedded-opentype'),
        url('../assets/fonts/Gotham/Gotham-Bold.woff2') format('woff2'),
        url('../assets/fonts/Gotham/Gotham-Bold.woff') format('woff'),
        url('../assets/fonts/Gotham/Gotham-Bold.ttf') format('truetype'),
        url('../assets/fonts/Gotham/Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Book';
    src: url('../assets/fonts/Gotham/Gotham-Book.eot');
    src: url('../assets/fonts/Gotham/Gotham-Book.eot?#iefix')
            format('embedded-opentype'),
        url('../assets/fonts/Gotham/Gotham-Book.woff2') format('woff2'),
        url('../assets/fonts/Gotham/Gotham-Book.woff') format('woff'),
        url('../assets/fonts/Gotham/Gotham-Book.ttf') format('truetype'),
        url('../assets/fonts/Gotham/Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BERNIER Distressed';
    src: url('../assets/fonts/Bernierd/BERNIERDistressed-Regular.eot');
    src: url('../assets/fonts/Bernierd/BERNIERDistressed-Regular.eot?#iefix')
            format('embedded-opentype'),
        url('../assets/fonts/Bernierd/BERNIERDistressed-Regular.woff2')
            format('woff2'),
        url('../assets/fonts/Bernierd/BERNIERDistressed-Regular.woff')
            format('woff'),
        url('../assets/fonts/Bernierd/BERNIERDistressed-Regular.ttf')
            format('truetype'),
        url('../assets/fonts/Bernierd/BERNIERDistressed-Regular.svg#BERNIERDistressed-Regular')
            format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Alternate Gothic No3 D';
    src: url('../assets/fonts/Alternate/AlternateGotNo3D.eot');
    src: url('../assets/fonts/Alternate/AlternateGotNo3D.eot?#iefix')
            format('embedded-opentype'),
        url('../assets/fonts/Alternate/AlternateGotNo3D.woff2') format('woff2'),
        url('../assets/fonts/Alternate/AlternateGotNo3D.woff') format('woff'),
        url('../assets/fonts/Alternate/AlternateGotNo3D.ttf') format('truetype'),
        url('../assets/fonts/Alternate/AlternateGotNo3D.svg#AlternateGotNo3D')
            format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kellog';
    src: url('../assets/fonts/Kellog/Filmotype-Kellog.ttf') format('truetype'),
    url('../assets/fonts/Kellog/Filmotype-Kellog.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Alternate Gothic No2 D';
    src: url('../assets/fonts/Alternate/AlternateGothicNo2DRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('../assets/fonts/Oswald/Oswald-DemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
