#aboutUsSection {
    background-image: url('../../assets/images/bg_full.webp');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0px;
}

h2#why-we-love-isadora {
    font-size: 60px;
}

.au-intro-box {
    margin-bottom: 50px;
}

.au-title {
    font-size: 52px;
}

.au-title span {
    font-size: 62px;
}

.au-intro {
    font-size: 24px;
}

.au-intro span {
    font-size: 28px;
}

.au-icon {
    max-width: 160px !important;
    margin: 0px 10px 30px;
}

.au-icon-t1 {
    font-size: 14px !important;
    text-transform: uppercase;
    line-height: 24px;
}

.au-icon-t1 span {
    font-size: 36px !important;
    text-transform: lowercase;
}

.au-icon-t2 {
    font-size: 14px !important;
    text-transform: uppercase;
    line-height: 16px;
}

.au-icon-t2 span {
    font-size: 32px !important;
    text-transform: lowercase;
    line-height: 32px;
}

.au-icon img {
    max-width: 100px !important;
}
.au-icon h3 {
    margin-top: 10px;
}
.au-icon-special {
    max-width: 160px;
    margin-bottom: 30px;
}
.au-icon-special img {
    max-width: 100px !important;
}

.au-icon-special h3 {
    margin-top: 10px;
}

.au-preparation-box {
    margin-top: 60px;
}

.au-preparation-box h2 {
    font-size: 56px;
    margin-bottom: 70px;
    line-height: 56px;
}

.au-preparation-box h2 span {
    font-size: 62px;
    margin-bottom: 70px;
}

.au-preparation-box h3 {
    font-size: 32px;
    padding-left: 35px;
}

.au-deco-line {
    height: 10px;
    width: 120px;
    background-color: var(--color-orange1);
}

.au-preparation-box .au-deco-line {
    margin-bottom: 30px;
    margin-left: 35px;
}

.au-preparation-box ol {
    list-style: none;
    padding-left: 0px;
    font-size: 22px;
}

.au-preparation-box li {
    padding-left: 35px;
    margin-bottom: 0px;
    background-position: 0px 5px;
    background-size: 25px;
    background-repeat: no-repeat;
}

.au-preparation-box li:nth-child(1) {
    background-image: url('../../assets/images/numbers-li\ 11.png');
}

.au-preparation-box li:nth-child(2) {
    background-image: url('../../assets/images/numbers-li\ 12.png');
}

.au-preparation-box li:nth-child(3) {
    background-image: url('../../assets/images/numbers-li\ 13.png');
}

.au-preparation-box img {
    height: auto !important;
    align-self: start;
    max-width: 45%;
}

.au-instructions-box {
    margin-top: 60px;
    font-size: 22px;
}

@media screen and (min-width: 768px) {
    .au-intro {
        font-size: 32px;
    }

    .au-intro span {
        font-size: 36px;
    }

    .au-preparation-box2 .au-img-box {
        max-width: 200px;
    }

    .au-intro-box {
        max-width: 1000px !important;
    }

    .au-preparation-box img {
        align-self: start;
        max-width: 80%;
    }

    .au-img-1 {
        margin-top: 30px;
    }

    .au-preparation-box1 {
        margin-bottom: 80px;
    }

    .au-preparation-box1 .au-img-box {
        max-width: 222px;
    }
}

@media screen and (min-width: 1024px) {
    #aboutUsSection {
        padding: 80px 0px;
    }

    .au-intro-box {
        margin-bottom: 100px;
    }

    .au-icon {
        border-bottom: 3px var(--color-orange1) solid;
        margin: 0px 10px 30px;
    }
    .au-center-image {
        max-width: 450px !important;
    }
    .au-icon-special {
        max-width: fit-content;
        border-bottom: 0px var(--color-orange1) solid;
        max-width: 300px;
        margin-bottom: 0px;
    }
    .au-icon-special img {
        max-width: 100px !important;
        margin-right: 10px !important;
    }

    .au-icon-special h3 {
        border-bottom: 3px var(--color-orange1) solid;
        padding-bottom: 8px;
        margin-top: 0px;
    }

    .au-preparation-box1 {
        margin-bottom: 0px;
    }

    .au-preparation-box2 {
        padding-left: 5% !important;
    }

    .au-img-2 {
        margin-top: -20px;
    }
}

#free-pba-mobile{
    display: none !important;
}

@media screen and (max-width: 576px) {
    .my-1dot25 {
        margin-bottom: 1.25rem;
        margin-top: 1.25rem;
    }

    #icon-bpa-free{
        display: none !important;
    }
    #free-pba-mobile{
        display: flex !important;
    }
    #free-pba-mobile>div>img.img-fluid{
        max-width: 100% !important;
    }
    .au-icon, #preservatives-free-mb {
        border-bottom: 3px var(--color-orange1) solid;
        padding-bottom: 1rem;
    }
    .au-deco-line{
        visibility: hidden;
    }
    #free-pba-mobile{
        padding: 2rem 0;
    }
}