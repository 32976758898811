body {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

#beans-super-f {
    background: url('../../assets/images/bg_full.webp');
    background-size: cover;
    background-position: center center;
    position: relative;
    padding-top: 150px;
}

.bs-f-top-bg {
    position: absolute;
    top: 0;
}

#beans-super-f .container {
    position: relative;
    min-height: 600px;
    max-width: 1140px;
    margin: auto;
    text-align: center;
    position: relative;
    z-index: 2;
}

#beans-super-f img {
    max-width: 100%;
    margin-bottom: 10px;
}

.sf-ico-1 {
    position: absolute;
    left: 0;
    top: 200px;
}

.sf-ico-2 {
    position: absolute;
    left: 0;
    bottom: 350px;
}

.hach {
    margin-bottom: 50px !important;
}

.sf-ico-3 {
    position: absolute;
    right: 35px;
    top: 200px;
}

.sf-ico-4 {
    position: absolute;
    right: 0;
    bottom: 350px;
}

.sf-plato-home {
    width: 60%;
    margin: auto;
}

.sf-plato-home-bot {
    width: 60%;
    margin: auto;
    margin-top: 70px;
    position: relative;
    min-height: 250px;
}

.sf-ico-5 {
    position: absolute;
    left: 0;
    bottom: 50px;
}

.sf-ico-6 {
    position: absolute;
    right: 0;
    bottom: 50px;
}

#beans-super-f h1 {
    font-family: 'BERNIER Distressed';
    margin: 0;
    padding: 0;
    font-size: 130px;
    color: #392715;
    font-weight: 400;
    line-height: 100px;
}

#beans-super-f h2 {
    font-family: 'BERNIER Distressed';
    background: #ea7f14;
    color: #fff;
    font-size: 45px;
    width: auto;
    max-width: 280px;
    margin: 0 auto;
    font-weight: 400;
}

#beans-super-f h4 {
    font-family: 'BERNIER Distressed';
    margin: 0;
    padding: 0;
    font-size: 40px;
    color: #392715;
}

#beans-super-f p {
    font-family: 'Gotham Bold';
    color: #402c1c;
    margin: 0;
}

.mt10 {
    margin-top: 10px;
}

.mb30 {
    margin-bottom: 30px !important;
}

.our-beans {
    padding-top: 90px;
}

.our-beans h3 {
    font-family: 'BERNIER Distressed';
    margin: 0;
    padding: 0;
    font-size: 115px;
    color: #392715;
    font-weight: 400;
    line-height: 100px;
}

.our-beans h3 span {
    font-family: 'BERNIER Distressed';
    margin: 0;
    padding: 0;
    font-size: 115px;
    color: #ea7f14;
    font-weight: 400;
    line-height: 100px;
}

.our-beans p {
    color: #392715;
    font-size: 20px;
    max-width: 420px;
    margin: 20px auto !important;
}

.our-beans p span {
    font-family: 'Gotham Book';
}

#bolsas {
    background: #ea7f14;
    width: 100%;
    padding: 50px 0;
    margin-top: -300px;
    padding-top: 350px;
    position: relative;
    z-index: 1;
    padding-bottom: 150px;
}

#bolsas .container {

    max-width: 1140px;
    margin: auto;
    text-align: center;
}

.bolsas-icons {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.bolsas-icons div {
    padding: 0 30px;
    border-right: solid 3px #fff;
    max-height: 120px;
    display: flex;
    align-content: center;
    align-items: center;
}

.bolsas-icons div:last-child {

    border-right: hidden;
}

.bolsas-icons div img {
    height: 130px;
}

#occasions {
    padding-bottom: 50px;
}

.dish-block {
    display: flex;
    justify-content: center;
    margin-top: -100px;
    position: relative;
    z-index: 2;
}

.dish-block img {
    max-width: 350px;
    height: 350px;
    margin: 0 20px 20px 20px;
}

#occasions h3 {
    font-family: 'BERNIER Distressed';
    color: #ea7f14;
    font-size: 60px;
    font-weight: 400;
    text-align: center;
    margin-top: 0;
    line-height: 60px;
    margin-bottom: 30px;
}

#occasions h3 span {
    font-family: 'Kellog';
}

#occasions p {
    font-family: 'Gotham Book';
    color: #362413;
    margin: 0 auto;
    max-width: 600px;
    text-align: center;
    font-size: 16px;
}

#occasions p span {
    font-family: 'Gotham Bold';
}


@media (max-width: 1150px) {
    .dish-block img {
        max-width: 300px;
        height: 300px;
        margin: 0 20px 20px 20px;
    }
}

@media (max-width: 768px) {


    .dish-block {
        overflow: hidden;
    }

    .bolsas-icons {
        display: block;
    }

    .bolsas-icons div {
        display: inline;
        border-right: hidden;
    }

    .sf-ico-1 {
        position: relative;
        left: 0;
        top: 0;
        right: 0;
        width: 50%;
        float: left;
    }

    .sf-ico-2 {
        position: relative;
        left: 0;
        right: 0;
        bottom: auto;
        width: 50%;
        float: left;
    }

    .sf-plato-home {
        clear: both;
    }

    .sf-ico-3 {
        position: relative;
        right: 0;
        top: auto;
        width: 50%;
        float: left;
    }

    .sf-ico-4 {
        position: relative;
        right: 0;
        bottom: auto;
        width: 50%;
        float: left;
    }

    .sf-ico-5 {
        position: relative;
        left: 0;
        bottom: 0;
        width: 50%;
        float: left;
    }

    .sf-ico-6 {
        position: relative;
        right: 0;
        bottom: 0;
        width: 50%;
        float: left;
    }

    .sf-plato-home-bot {
        width: 100%;
        margin: auto;
        margin-top: 0;
    }

    #beans-super-f .container {
        clear: both;
    }


}


@media (max-width: 480px) {

    #bolsas>div.container{
        margin-top: 25px;
    }

    .sf-plato-home {
        width: 100%;
    }

    #beans-super-f h1 {
        font-size: 89px;
        line-height: 90px;
    }

    #beans-super-f h2 {
        font-size: 30px;
    }

    .sf-ico-1 {
        width: 50%;
        float: left;
    }

    .sf-ico-2 {
        width: 50%;
        float: left;
    }

    .sf-ico-3 {
        width: 50%;
        float: left;
    }

    .sf-ico-4 {
        width: 50%;
        float: left;
    }

    .sf-ico-5 {
        width: 50%;
        float: left;
    }

    .sf-ico-6 {
        width: 50%;
        float: left;
    }

    .boxxfa {
        margin-bottom: 18px !important;
    }

    #beans-super-f h4 {
        font-size: 25px;
    }

    #beans-super-f h4 {
        font-size: 25px;
    }

    .our-beans h3 {
        font-size: 70px;
        line-height: 70px;
    }

    #bolsas {
        margin-top: -150px;
        padding-top: 120px;
    }

    #occasions h3 {
        font-size: 40px;
        line-height: 40px;
    }

    .bolsas-icons div {
        display: inline;
        border-right: hidden;
        padding: 10px;
    }

    .bolsas-icons div img {
        height: 100px;
        margin-bottom: 10px;
    }

    .dish-block img {
        max-width: 100%;
        display: block;
        height: auto;
        margin: auto;
        margin-top: 0 !important;
    }

    .dish-block {
        display: inline;
    }

    #bolsas {
        padding-bottom: 100px;
    }

    .sf-ico-1 img {
        max-width: 100px !important;
        margin: auto;
        margin-bottom: 10px;
    }

    .sf-ico-2 img {
        max-width: 100px !important;
        margin: auto;
        margin-bottom: 10px;
    }

    .sf-ico-3 img {
        max-width: 100px !important;
        margin: auto;
        margin-bottom: 10px;
    }

    .sf-ico-4 img {
        max-width: 100px !important;
        margin: auto;
        margin-bottom: 10px;
    }

    .sf-ico-5 img {
        max-width: 100px !important;
        margin: auto;
        margin-bottom: 10px;
    }

    .sf-ico-6 img {
        max-width: 100px !important;
        margin: auto;
        margin-bottom: 10px;
    }

    #occasions>div>div.dish-block{
        display: flex;
        flex-direction: row;
        margin: auto;
        padding: 1rem 1rem 1rem;
        height: auto;
    }
    #occasions>div>div.dish-block>div.animate__animated{
        min-height: 120px;
        display: block;
    }


    div.sf-ico-1, 
    div.sf-ico-2
    {
        position: relative;
        left: 0;
        top: 330px;
    }

    div.container>div.sf-plato-home{
        position: relative;
        bottom: 230px;
    }
    
    .hach {
        margin-bottom: 65px !important;
    }
}