#single-recipe {
    #recipe-main-pic {
        object-fit: cover;
        object-position: center;
        width: 100%;
        
    }

    .share-span {
        font-size: 19px;
    }

    #data-column {
        margin-top: 3rem;
        h2 {
            font-size: 56px;
            line-height: 56px;
        }
    }
}

.border-div {
    max-width: 250px;
    height: 3px;
    background-color: var(--color-darker-brown);
    margin: auto;
}

#box-ingredients {
    img {
        max-height: 70px;
    }
}
#box-preparation{
    img {
        max-height: 70px;
    }
}

#ingredients-list {
    padding-left: 0px;

    li::before {
        content: "\2022";
        color: var(--color-orange1);
        font-weight: bold;
        display: inline-block;
        width: 1em;
    }
}

#related-recipes-sec {
    background-color: var(--color-orange1);
    h2 {
        font-size: 48px;
        line-height: 48px;
    }
}

#box-ingredients img {
    margin-bottom: 10px;
}

#box-preparation img {
    margin-bottom: 10px;
}

@media screen and (min-width: 768px){
    .mt-single-recipe {
        margin-top: 64px !important;
    }
    
}

@media screen and (min-width: 992px){
    #single-recipe {
        #recipe-main-pic {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 90%;
            max-height: 110vh;
        }
    }

    #data-column {
        padding-left: 3rem;
        margin-top: 0rem;

        h2 {
            font-size: 56px;
            line-height: 56px;
        }
    }
}