#type-of-bean-sec ul {
    padding-left: 0 !important;

    li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        a {
            font-size: 19px;
            line-height: 20px;
            padding: 10px;
        }

        .arrow-tab {
            height: 18px;
            width: 18px;
            border-radius: 4px;
            background-color: var(--color-orange2);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            display: none;
        }
    }

    .active {
        background-color: var(--color-orange2);
        & + .arrow-tab {
            display: inline-block;
            margin-left: -10px;
        }
    }
}
