@import '../../styles/bootstrap-utilities';

.header {
    --nav-item-logo-height: 80px;
    --nav-link-bg-color: var(--color-davis-grey);
    --header-font-size: 17px;
    --nav-link-white-space: inherit;
    background-color: var(--color-orange1);
    color: var(--color-white);
    font-family: var(--font-montserrat);
    font-weight: 700;
    font-size: var(--header-font-size);
    z-index: 99;
    @include media-breakpoint-up(lg) {
        --header-font-size: clamp(15px, 1.3vw, 18px);
        --nav-link-bg-color: none;
        --nav-link-color-active: var(--color-black);
        --nav-link-white-space: nowrap;
    }
    &__logo {
        -webkit-filter: drop-shadow(5px 8px 8px rgba(0, 0, 0, 0.224));
        filter: drop-shadow(5px 8px 8px rgba(0, 0, 0, 0.224));
        width: 100%;
        &--mobile {
            max-width: 130px;
        }
        &--desktop {
            max-width: 300px;
        }
    }

    &__navbar {
        &-mobile {
            min-width: 100vw;
            margin-left: -0.75rem;
        }
        &-social,
        &-link {
            padding: 0 0.75rem;
        }
        &-search {
            margin-right: calc(0.75rem * 1.5);
        }
        &-link {
            -webkit-transition: 0.5s ease;
            transition: 0.5s ease;
            white-space: var(--nav-link-white-space);
            &:hover {
                background-color: var(--nav-link-bg-color);
            }
            &.active {
                color: var(--color-black);
                background: none;
            }
        }
        &-item {
            z-index: 1;
            &--logo {
                height: var(--nav-item-logo-height);
                a {
                    margin-top: calc(var(--nav-item-logo-height) / (-5));
                }
            }
        }
        &-social {
            max-width: 1500px;
            z-index: 10;
        }
        &-desktop {
        }
    }
    &__trackbar {
        width: 100px;
        height: 100px;
        bottom: 0;
        left: 0;
        z-index: 0;

        &::after {
            content: '';
            position: absolute;
        }
        &::after {
            width: 90%;
            left: 5%;
            height: 8px;
            background-color: var(--color-feldspar);
            bottom: 15px;
            -webkit-animation: scaleX 0.5s 0.7s forwards ease;
            animation: scaleX 0.5s 0.7s forwards ease;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
        }
        &-box {
            position: absolute;
            width: 90%;
            height: 90%;
            background-color: var(--color-orange1);
            bottom: -15px;
            left: 5%;
            -webkit-transform: translateY(-50%) rotate(45deg);
            transform: translateY(-50%) rotate(45deg);
            -webkit-animation: slideDown 0.5s 1s forwards ease;
            animation: slideDown 0.5s 1s forwards ease;
        }
        &--show {
            -webkit-transition: 1s ease;
            transition: 1s ease;
        }
        &-container {
            height: 78px;
            left: 0;
            background-color: var(--color-orange1);
        }
    }
    a.header__navbar-link.active{
        color: #613A1F;
    }
}



#root header.header position-relative{
    height: 200px
}


a#alx-logo-gral{
    width: fit-content;
    margin: -60px auto 0px;
    z-index: 1;
}

a#alx-logo-gral img.header__logo--desktop{
        max-width: 250px;
}


ul#alx-menu-gral{
    margin-top: 10px;
}

#alx-div-menu-h{
    height: 130px;
    background-color: var(--color-orange1);
    z-index: 10;
}
.header__trackbar-box{
    display: none;
}
.header__trackbar:after{
    display: none;
}
