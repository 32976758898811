@import '../../styles/bootstrap-utilities';

.products-navbar {
    --padding-top: 1rem;
    --bg-color: var(--color-orange1);
    --title-color: var(--color-white);
    --title-font-size: 0.8rem;
    --triangle-display: none;
    --navbar-nav-min-width: 100vw;
    --navbar-nav-margin-left: -0.75rem;
    --navbar-container: hidden;
    --navlink-bg-hover: var(--color-davis-grey);
    @include media-breakpoint-up(md) {
        --bg-color: transparent;
        --title-color: var(--color-black);
        --title-font-size: clamp(0.4rem, 1.3vw, 0.8rem);
        --triangle-display: block;
        --navbar-nav-min-width: auto;
        --navbar-nav-margin-left: 0%;
        --navbar-container: inherit;
        --navlink-bg-hover: none;
    }
    @include media-breakpoint-up(lg) {
        --padding-top: 5rem;
    }
    font-family: var(--font-gotham-bold);
    &.navbar {
        padding-top: var(--padding-top);
    }
    &__nav {
        min-width: var(--navbar-nav-min-width);
        margin-left: var(--navbar-nav-margin-left);
    }
    &__container {
        background-color: var(--bg-color);
        overflow: var(--navbar-container);
    }
    &__product-container {
        cursor: pointer;
    }
    &__title {
        color: var(--title-color);
        font-size: var(--title-font-size);
        line-height: 1.5;
    }
    &__link {
        &:hover {
            background-color: var(--navlink-bg-hover);
            -webkit-transition: 0.5s ease;
            transition: 0.5s ease;
        }
        &.active {
            .products-navbar__title {
                position: relative;
                background-color: var(--color-indian-yellow);
                color: #613A1F;
                z-index: 0;
                &::after {
                    display: var(--triangle-display);
                    content: '';
                    position: absolute;
                    bottom: -1rem;
                    width: 3rem;
                    height: 3rem;
                    left: 50%;
                    -webkit-transform: translateX(-50%) rotate(45deg);
                    transform: translateX(-50%) rotate(45deg);
                    background-color: inherit;
                    border-radius: 0 0 1rem 0;
                    z-index: -1;
                }
            }
        }
    }

    .products-navbar__title
    {
        color: #613A1F;
    }
}
/* .productContainer {
    a {
        .productTitleNav {
            color: #000;
        }
        &.active {
            .productTitleNav {
                background-color: #efa04e;
            }
        }
    }
} */
