.alx-back-seccion{
    background-image: url('../../assets/images/bg_full.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-bottom: 100px;
}
.alx-banner{
    margin-bottom: 40px;
}
.alx-img-full{
    width: 100%;
    max-width: 100%;
}
.alx-receta-min{
    margin-bottom: 40px;
    display: block;
}
.alx-receta-min img{
    margin-bottom: 10px;
    width: 100% !important;

}


@media only screen and (max-width: 991px) {

    .alx-back-seccion{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .alx-banner{
        margin-bottom: 10px;
    }
  
}

@media only screen and (max-width: 575px) {

    .alx-back-seccion{
        padding-top: 0px;
        padding-bottom: 20px;
    }
    
    #alx-div-banner-home-noticias{
        padding-left: 0px;
        padding-right: 0px;
    }
}