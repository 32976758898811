footer {
    background-color: var(--color-orange1);

    .active {
        color: #613A1F !important;
    }
}

.copyright-box {
    background-color: #FF7700;
}

.logo-footer {
    filter: drop-shadow(5px 8px 14px rgba(211, 56, 0, 1));
    max-width: 290px !important;
}

footer h2 {
    font-size: 18px;
    margin-bottom: 10px;
}

#div-sm-footer ul {
    padding-left: 0px !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}