.difficulty-square {
    display: inline-block;
    height: 10px;
    width: 10px;
    border: 1px solid var(--color-cafe-noir);
    margin-right: 1px;
}
.full-square {
    background-color: var(--color-cafe-noir);
} 

#single-recipe {
    .difficulty-square {
        height: 18px;
        width: 18px;
        margin-right: 2px;
        border: 2px solid var(--color-cafe-noir);
    }

}