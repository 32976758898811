#RecipesHero {
    background: url('../../assets/images/Banner_Recipesop.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    position: relative;
    min-height: 500px;

    h1 {
        font-size: 86px;
        line-height: 76px;
    }

    p {
        text-shadow: 3px 0px 2px rgb(0, 0, 0);
        margin-top: 20px;
        font-size: 18px;
        font-weight: 600;
        span {
            font-weight: 700;
        }
    }
}

#sec2-recipes {
    z-index: 1 !important;
    margin-top: -20px;
    background-image: url('../../assets/images/bg_full.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#recipes-taste-section {
    background-color: var(--color-orange1);

    p {
        font-size: 18px;
    }

    .orange-btn {
        background-color: #fec881;
    }
}

@media screen and (min-width: 992px) {
    #RecipesHero {
        h1 {
            font-size: 106px !important;
            line-height: 96px;
        }
    }
}

@media screen and (max-width: 576px) {
    .row {
        .recipe-card {
            margin-bottom: 2.5rem;
        }
    }
}
