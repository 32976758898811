.thumbnailImage {
    width: 100%;
}

#productsSection {
    background-image: url('../../assets/images/textura_fondo.webp');
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: cover;
}

h4.productTitle > span {
    font-size: 4rem;
    font-family: 'Kellog';
}

.pt-6 {
    padding-top: 5rem;
}

.py-6 {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.productImage,
.productImageBackside {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: auto;
}

.productTitle {
    font-family: 'BERNIER Distressed';
    font-size: 3rem;
    text-align: center;
    color: #eb8316;
}

.productDescription,
.hoverInstructions {
    font-weight: bold;
    color: #58392c;
}

.ingredients-nutrition-cont {
        button.selectors {
        display: inline;
        width: auto;
        color: #58392c;
        font-weight: bold;
        cursor: pointer;
        padding: 0.5rem 0.8rem;
        text-transform: uppercase;
    }
}

button.btn-primary,
a.btn-primary,
a.btn-primary:hover,
button.btn-primary:hover,
button.btn-primary:focus,
a.btn-primary:focus {
    background-color: #efa459;
    border: none;
    border-radius: 0;
    color: #292626;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0.5rem 1rem;
    width: 130px;
    margin: auto;
    outline: none;
    box-shadow: none;
}

.ingredients-nutrition-cont {
    button.selectors.active {
    background-color: #efa04e;
    }
} 

.nutritionalImg {
    width: 100%;
    max-width: 360px !important;
}

.font-kellog-md{
    font-family: var(--font-kellog);
    font-size: 1.3rem;
    font-weight: 500;
}

@media (min-width: 768px) {
    div.row > div.col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 12%;
    }
    #col-text {
        padding-right: 5%;
    }
}

@media (min-width: 992px) {
    div.row > div.col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 10%;
    }
}

@media (max-width: 768px) {
    #navigationProductsContainer {
        display: none;
    }
}

@media (max-width: 576px) {
    #productsSection {
        background-position: center;
    }
    #main-product-row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
}
