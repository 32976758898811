@forward './fonts';
@forward '~normalize.css';
@forward '~bootstrap/scss/bootstrap';
@forward './utilities';

:root {
    /* Fonts */
    --font-gotham-bold: 'Gotham Bold', sans-serif;
    --font-gotham-book: 'Gotham Book', sans-serif;
    --font-montserrat: 'Montserrat', sans-serif;
    --font-bernierd: 'BERNIER Distressed', sans-serif;
    --font-alternate: 'Alternate Gothic No3 D', sans-serif;
    --font-alternate-regular: 'Alternate Gothic No2 D', sans-serif;
    --font-kellog: 'Kellog', sans-serif;
    --font-source-sans-italic: 'Source Sans Pro', sans-serif;
    --font-oswald: 'Oswald', sans-serif;
    /* Colors */
    --color-beer: #ea7f14;
    --color-white: #fff;
    --color-black: #000;
    --color-pine-tree: #292c2c;
    --color-royal-brown: #58392c;
    --color-orange1: #ff8101;
    --color-linen: #e3f2fd;
    --color-cafe-noir: #4b2e21;
    --color-davis-grey: #55595c;
    --color-darker-brown: #3c291a;
    --color-orange2: #ffa049;
    --color-feldspar: #f8d3ad;
    --color-indian-yellow: #efa04e;
}

:export {
    colorBeer: var(--color-beer);
}

body {
    font-display: swap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a,
a:hover {
    text-decoration: none;
    color: inherit;
}

ul {
    list-style-type: none;
}

a.custom-brown-color,
button.custom-brown-color,
a.custom-brown-color:hover,
button.custom-brown-color:hover,
.custom-brown-color{
    color: #613A1F;
}

link,
a,
button {
    cursor: pointer;
}
button {
    border: none;
    background: none;
}
