.ham-menu {
    width: 30px;
    height: 20px;
    position: relative;
    &--open span {
        &:nth-child(1),
        &:nth-child(4) {
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            opacity: 0;
        }
        &:nth-child(2) {
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        &:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
    }
    span {
        background-color: var(--color-linen);
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 10px;
        }

        &:nth-child(4) {
            top: 20px;
        }
    }
}
