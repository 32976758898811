$shadow_color: #996642;

.orange-btn {
    background-color: var(--color-orange2);
    border: none;
    padding: 3px 10px;
    font-family: 'Montserrat';
    font-weight: 700;
    color: #201e1e;
    text-transform: uppercase;
    transition: all ease-in 0.1s;
}

.orange-btn:hover {
    transform: scale(1.05);
}

.floating-element {
    display: block;
    position: absolute;
    background: no-repeat center;
    background-size: contain;
}

img#logo-home {
    max-width: 350px;
}

section#home-sec5{
    height: 400px;
    background-image: url('../../assets/images/banner_isadora_fh.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: -1px;
}

#homeHeroSection {
    background: url('../../assets/images/hero-home-bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 10px;
    position: relative;

    h1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 70px;
        text-shadow: 7px 5px 5px rgb(109, 57, 31);
        .sign {
            font-size: 50px;
            line-height: 50px;
            font-family: var(--font-alternate);
            color: #fff278;
        }
        .title-number {
            font-size: 90px;
            line-height: 90px;
            color: #fff278;
        }
    }
    .subtitle {
        font-size: 36px;
        text-shadow: 3px 4px 3px rgb(109, 57, 31);
        line-height: 40px;
    }

    img {
        margin-top: 30px;
        position: relative;
        z-index: 110;
    }

    nav ul {
        font-size: 20px;
        margin-bottom: 0px;
        li a:hover {
            transition: all 0.1s ease-in-out;
        }
    }

    li a:hover {
        border-bottom: 5px white solid;
    }

    #logo-home {
        filter: drop-shadow(5px 8px 8px $shadow_color);
        margin-top: -60px;
    }
}

#beans-plate {
    height: 300px;
    width: 300px;
    background-image: url('../../assets/images/Plato_Frijoles_Refritos_Bayos.png');
    background-size: cover;
    margin-bottom: -150px;
}



#alx-noticia-home-footer-arroz{
    background: transparent url('../../assets/images/banner-isadora-rice-new-product.png') no-repeat scroll 0px 0px;
    background-size: cover;
    width: 100%;
    padding-top: 32.60%;
    position: relative;
}
#alx-noticia-home-footer-arroz-btn{
    position: absolute;
    display: block;
    border-radius: 10px;
    text-align: center;
    background-color: #DE166F;
    top: 72.5%;
    left: 24%;
    margin-top: -30px;
    height: 60px;
    width: 14.53%;
    line-height: 60px;
    font-size: 25px;
    color: #FFFFFF;
    cursor: pointer;
}


@media screen and (max-width: 1500px) {

    #alx-noticia-home-footer-arroz-btn{
        margin-top: -25px;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        
    }

}

@media screen and (max-width: 1199px) {

    #alx-noticia-home-footer-arroz-btn{
        margin-top: -23px;
        height: 46px;
        line-height: 46px;
        font-size: 20px;
        
    }

}
@media screen and (max-width: 991px) {
    #alx-noticia-home-footer-arroz-btn{
        margin-top: -10px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        width: 150px;
        left: 20%;
        
    }
}

@media screen and (max-width: 767px) {

}
@media screen and (max-width: 567px) {

    #alx-noticia-home-footer-arroz{
        background: transparent url('../../assets/images/banner-isadora-rice-new-product-mobile-6.png') no-repeat scroll 0px 0px;
        background-size: 100% auto;
        padding-top: 108%;
    }
     #alx-noticia-home-footer-arroz-btn{
        margin-top: 0px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        width: 150px;
        left: 0%;
        top:0%;
        margin: 0px auto 40px;;
        position: relative;
        
    }

}

/*SEC 2*/
#homeBox2 {
    z-index: 1 !important;
    margin-top: -20px;
    background-image: url('../../assets/images/bg_full.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}

#home-sec2 {
    position: relative;

    h2 {
        margin-top: 155px;
        font-size: 60px;
        line-height: 54px;

        span {
            font-size: 52px;
        }
    }

    p {
        font-size: 18px;
    }
}

/*SEC 3*/

#home-sec3 {
    position: relative;
    margin-top: 60px;

    h2 {
        font-size: 48px;
        line-height: 54px;

        span {
            font-size: 54px;
        }
    }

    p {
        font-size: 20px;
        line-height: 24px;
    }
}

.home-recipes-img {
    height: auto !important;
    width: 290px !important;
}

/*SEC 4*/

#home-sec4 {
    position: relative;

    h2 {
        font-size: 120px;
        line-height: 54px;
        margin-bottom: 15px;

        span {
            font-size: 42px;
            color: white;
            background-color: var(--color-orange1);
            padding: 5px 12px;
        }
    }

    h3 {
        font-size: 24px;
        line-height: 24px;
        max-width: 215px;
        margin-left: auto;
        margin-right: auto;

        span {
            font-size: 72px;
            line-height: 56px;
        }
    }

    .box-right {
        h3,
        p,
        .home-deco-line {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .home-deco-line {
        height: 5px;
        max-width: 180px;
        background-color: var(--color-orange1);
        margin-left: auto;
        margin-right: auto;
    }

    p {
        font-size: 16px;
        line-height: 20px;
        max-width: 180px;
        margin-top: 5px;
        margin-left: auto;
        margin-right: auto;
    }

    .home-circle {
        display: inline-block;
        height: 10px;
        width: 10px;
        background-color: var(--color-orange1);
        border-radius: 100px;
        margin-right: 5px;
    }

    .box-bottom {
        h3 {
            font-size: 42px;
            max-width: 100%;
            line-height: normal;
        }

        p {
            font-size: 18px;
            line-height: 20px;
            max-width: 100%;
            margin-top: 5px;
        }
    }
}

.plate-beans-home {
    filter: drop-shadow(-4px 7px 11px $shadow_color);
}

#home-cilantro2-img {
    background-image: url('../../assets/images/Cilantro.png');
    height: 100px;
    width: 100px;
    top: 200px;
    right: 0px;
}

/* Footer */
img#mex-is-away {
    width: 90%;
    margin-left: 10%;
}

/*SEC-5 BANNER*/

#home-sec5 {
    background-image: url(../../assets/images/Banner_Guisos.png);
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    position: relative;

    .container {
        position: relative;
    }

    .orange-btn {
        background-color: #fec881;
    }

    #banner-guiso-img {
        margin-bottom: -30px;
        margin-top: 20px;
    }

    p {
        font-size: 24px;
    }

    #logo-kroger {
        max-height: 70px;
    }

    #new-badget {
        background: #d91274;
        font-size: 30px;
        line-height: 30px;
        color: white;
        font-family: var(--font-alternate);
        display: block;
        position: absolute;
        top: 0%;
        left: 5%;
        padding: 15px 25px 15px;
        border-radius: 0px 0px 15px 15px;
        filter: drop-shadow(3px 0px 7px rgba(0, 0, 0, 0.5));
        margin-left: 1rem;
    }

    #gradient-footer {
        background: rgb(255, 129, 1);
        background: linear-gradient(
            0deg,
            rgba(255, 129, 1, 1) 16%,
            rgba(255, 255, 255, 0) 86%
        );
        height: 60px;
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0px;
    }
}

@media screen and (min-width: 768px) {
    /*Hero*/
    #homeHeroSection {
        h1 {
            letter-spacing: -1px;
            font-size: 90px;
            /*text-shadow: 5px 5px 3px rgb(109, 57, 31);*/
            text-shadow: 10px 5px 5px rgb(109, 57, 31);

            .sign {
                font-size: 80px;
                line-height: 100px;
                font-family: var(--font-alternate-regular);
                color: #FFF278;
            }
            .title-number {
                font-size: 140px;
                line-height: 150px;
                color: #FFF278;
                margin-left: -15px;
                margin-top: -20px;

            }
        }

        .subtitle {
            font-size: 54px;
            /*text-shadow: 4px 4px 3px rgb(109, 57, 31);*/
            line-height: 40px;
            margin-left: -15px;
            text-shadow: 7px 4px 3px rgb(109, 57, 31);
        }
    }
    img {
        max-width: 500px;
    }

    #beans-plate {
        height: 500px;
        width: 500px;
        margin-bottom: -250px;
    }

    /*SEC 2*/

    #home-sec2 h2 {
        margin-top: 260px;
    }

    #home-1min-img {
        background-image: url('../../assets/images/microwaves.png');
        width: 150px;
        height: 150px;
        top: 90px;
        left: 0px;
    }

    #home-pouch-img {
        background-image: url('../../assets/images/Bayos_Refried.png');
        height: 400px;
        width: 300px;
        top: 50px;
        right: 0px;
        margin-right: -120px;
        filter: drop-shadow(-4px 7px 11px $shadow_color);
    }

    #home-beans-img {
        background-image: url('../../assets/images/beans-group.png');
        height: 120px;
        width: 120px;
        top: 20px;
        right: 55px;
    }

    #home-bean2-img {
        background-image: url('../../assets/images/bean2.png');
        height: 35px;
        width: 35px;
        top: 280px;
        left: 0px;
    }
    #home-bean3-img {
        background-image: url('../../assets/images/bean3.png');
        height: 35px;
        width: 35px;
        top: 370px;
        left: 50px;
    }
    #home-leaf1-img {
        background-image: url('../../assets/images/leaf1.png');
        height: 90px;
        width: 80px;
        top: 600px;
        left: -20px;
    }
    #home-leaf2-img {
        background-image: url('../../assets/images/leaf2.png');
        height: 80px;
        width: 60px;
        top: 520px;
        right: 0px;
    }
    #home-bean4-img {
        background-image: url('../../assets/images/bean1.png');
        height: 35px;
        width: 35px;
        top: 630px;
        right: 40px;
        transform: rotate(100deg);
    }
    #home-bean5-img {
        background-image: url('../../assets/images/bean4.png');
        height: 35px;
        width: 30px;
        top: 710px;
        right: 50px;
    }

    /*SEC 3*/

    #home-sec3 {
        margin-top: 100px;

        h2 {
            font-size: 60px;
            line-height: 54px;
            margin-top: 0px;

            span {
                font-size: 66px;
            }
        }
    }

    #home-beans-nachos-img {
        background-image: url('../../assets/images/b_n_w_plate.png');
        width: 232px;
        height: 232px;
        left: -80px;
        top: -50px;
    }

    #home-leaf3-img {
        background-image: url('../../assets/images/leaf1.png');
        height: 70px;
        width: 80px;
        right: -20px;
        transform: rotate(290deg);
    }

    #home-bean6-img {
        background-image: url('../../assets/images/bean3.png');
        height: 35px;
        width: 35px;
        top: 130px;
        right: 15px;
    }

    #home-bean7-img {
        background-image: url('../../assets/images/bean3.png');
        height: 35px;
        width: 35px;
        top: 225px;
        right: -8px;
        transform: rotate(290deg);
    }

    /*SEC 4*/

    #home-cilantro2-img {
        background-image: url('../../assets/images/Cilantro.png');
        height: 100px;
        width: 100px;
        top: 160px;
        right: 38px;
    }

    #home-sec4 {
        h3,
        p,
        .home-deco-line {
            margin-left: 0px;
            margin-right: auto;
        }
    }

    #home-sec4 .box-right {
        h3,
        p,
        .home-deco-line {
            margin-left: auto;
            margin-right: 0px;
        }
    }

    /* SEC 5 - BANNER*/

    #home-sec5 {
        .container {
            padding-top: 50px !important;
        }
        #banner-guiso-img {
            max-width: 80% !important;
        }
    }
}

@media screen and (min-width: 992px) {
    /*HERO*/
    #home-1min-img {
        width: 200px;
        height: 200px;
        left: 50px;
    }

    #home-pouch-img {
        height: 500px;
        width: 400px;
        top: 20px;
        margin-right: -150px;
    }
    /*SEC 2*/

    #home-sec2 {
        position: relative;

        h2 {
            margin-top: 250px;
        }
    }

    #home-beans-img {
        height: 150px;
        width: 150px;
        top: 20px;
        right: 180px;
    }
    #home-bean1-img {
        height: 40px;
        width: 40px;
        top: 60px;
    }
    #home-bean2-img {
        height: 35px;
        width: 35px;
        top: 280px;
        left: 250px;
    }
    #home-bean3-img {
        height: 35px;
        width: 35px;
        top: 370px;
        left: 50px;
    }
    #home-leaf1-img {
        height: 90px;
        width: 80px;
        top: 600px;
        left: -20px;
    }
    #home-leaf2-img {
        height: 80px;
        width: 60px;
        top: 520px;
        right: 0px;
    }
    #home-bean4-img {
        height: 35px;
        width: 35px;
        top: 630px;
        right: 40px;
        transform: rotate(100deg);
    }
    #home-bean5-img {
        height: 35px;
        width: 30px;
        top: 710px;
        right: 50px;
    }

    /*SEC 3*/
    #home-sec3 h2 {
        margin-top: 120px;
    }

    #home-beans-nachos-img {
        width: 400px;
        height: 400px;
        left: -80px;
        top: 0px;
    }

    #home-leaf3-img {
        background-image: url('../../assets/images/leaf1.png');
        height: 90px;
        width: 80px;
        right: 0px;
        transform: rotate(290deg);
    }

    #home-bean6-img {
        background-image: url('../../assets/images/bean3.png');
        height: 35px;
        width: 35px;
        top: 130px;
        right: 70px;
    }

    #home-bean7-img {
        background-image: url('../../assets/images/bean3.png');
        height: 35px;
        width: 35px;
        top: 270px;
        right: 20px;
        transform: rotate(290deg);
    }

    /*SEC 4*/

    #home-sec4 {
        .container-plate-text {
            max-width: 215px;
            padding: 0px !important;
        }
    }

    #home-cilantro1-img {
        background-image: url('../../assets/images/Cilantro.png');
        height: 100px;
        width: 100px;
        top: 40px;
        left: 0px;
        transform: rotate(300deg);
    }

    #home-cilantro2-img {
        background-image: url('../../assets/images/Cilantro.png');
        height: 100px;
        width: 100px;
        top: 200px;
        right: 200px;
    }

    /*SEC-5 BANNER*/
    #home-sec5 #new-badget {
        font-size: 60px;
        line-height: 56px;
    }
}
@media screen and (max-width: 768px) {
    section#home-sec5 {
        height: 300px;
    }
}

@media screen and (max-width: 576px) {
    section#home-sec5{
        height: 150px;
        background-image: url('../../assets/images/banner_isadora_6.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }    
}

@media screen and (min-width: 1300px) {
    section#home-sec5 {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 420px;
        margin-bottom: -1px;
    }
}

section#home-sec5{
    cursor: pointer;
}