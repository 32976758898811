/*Font Colors*/

.text-orangeIs {
    color: var(--color-orange1);
}

.text-brownIs {
    color: var(--color-royal-brown);
}

.text-darker-brownIs {
    color: var(--color-darker-brown);
}

.text-pineIs {
    color: var(--color-pine-tree);
}

/*Font Families*/

.font-gotham-bold {
    font-family: var(--font-gotham-bold);
}

.font-gotham-book {
    font-family: var(--font-gotham-book);
}

.font-bernierd {
    font-family: var(--font-bernierd);
}

.font-kellog {
    font-family: var(--font-kellog);
}
.font-alternate {
    font-family: var(--font-alternate);
}
.font-alternate-regular {
    font-family: var(--font-alternate-regular);
}

.font-montserrat {
    font-family: var(--font-montserrat);
}

.font-source-sans-italic {
    font-family: var(--font-source-sans-italic);
}

.font-oswald {
    font-family: var(--font-oswald);
}

.font-arial {
    font-family: Arial, sans-serif;
    font-weight: 700;
}

.text-shadow-brown {
    text-shadow: 3px 2px 0px rgba(127, 38, 41, 0.7);
}

.modalIsOpen {
    position: relative;

    &::after {
        content: '';
        width: 100%;
        height: 100vh;
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
    }
}

.circle-btn,
.circle-btn-orange {
    --size: 14vw;
    --max-width: 36px;
    width: var(--size);
    height: var(--size);
    max-width: var(--max-width);
    max-height: var(--max-width);
    background-color: var(--color-white);
    border-radius: 50%;
    color: var(--color-beer);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-filter: drop-shadow(3px 3px 3px rgba(75, 46, 33, 0.3));
    filter: drop-shadow(3px 3px 3px rgba(75, 46, 33, 0.3));
    cursor: pointer;
    a,
    button {
        color: inherit;
    }
    img {
        width: 100%;
    }
    &--bg-none {
        background: none;
    }
    &:hover {
        -webkit-animation: rubberBand 0.6s ease;
        animation: rubberBand 0.6s ease;
    }
}

.circle-btn-orange {
    color: var(--color-white);
    background-color: var(--color-orange1);
    filter: none;
}

/* Animations */
@-webkit-keyframes rubberBand {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
@keyframes rubberBand {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
@-webkit-keyframes slideDown {
    to {
        -webkit-transform: translateY(0) rotate(45deg);
        transform: translateY(0) rotate(45deg);
    }
}
@keyframes slideDown {
    to {
        -webkit-transform: translateY(0) rotate(45deg);
        transform: translateY(0) rotate(45deg);
    }
}

@-webkit-keyframes scaleX {
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes scaleX {
    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

