.modal-search {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.85);
    -webkit-backdrop-filter: blur(0.1rem);
    backdrop-filter: blur(0.1rem);
    z-index: 999;
    opacity: 0;
    -webkit-transition: opacity 0.5s 0.5s ease;
    transition: opacity 0.5s 0.5s ease;
    color: var(--color-white);
    &--show {
        opacity: 1;
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
    }
    &__input {
        background: none;
        border: none;
        outline: none;
        border-bottom: 1px solid var(--color-white);
        color: inherit;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        -webkit-transform-origin: left;
        transform-origin: left;
        &--show {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            -webkit-transition-delay: 0.5s;
            transition-delay: 0.5s;
        }
    }
    &__close {
        font-size: 2.5rem;
    }
}
