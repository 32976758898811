#wb-locations-box {
    #wb-map {
        min-height: 50vh;

        img {
            object-fit: cover;
            object-position: center;
            height: 100%;
            width: 100%;
            max-width: 100%;
        }
    }

    #wb-locations {
        #header-location-list {
            background-color: var(--color-orange1);
            padding-left: 12px !important;
            
        }

        #box-location-list::-webkit-scrollbar {
            width: 5px;
        }
          
        #box-location-list::-webkit-scrollbar-track {
            background: transparent;
        }
          
        #box-location-list::-webkit-scrollbar-thumb {
            background-color: gainsboro;
            border-radius: 20px;
        }

        #box-location-list {
            padding: 12px;
            scrollbar-width: thin;
            scrollbar-color: gainsboro transparent;

            ol {
                padding-left: 0px !important;


                li {
                    padding: 10px 0px;
                    border-bottom: 2px solid rgba(0, 0, 0, 0.496);

                    .number-list {
                        font-size: 14px;
                        position: absolute;
                        top: 0px;
                        line-height: 14px;
                    }

                    h3 {
                        font-size: 14px;
                        margin-bottom: 0px;
                    }

                    span {
                        font-size: 12px;
                    }

                    .btn-list {
                        width: 25px;
                        height: 25px;
                        color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #FBB312;
                        border-radius: 100px;
                        transition: all ease-in-out 0.3s;   
                    }

                    .btn-list:hover {
                        transform: scale(1.2);
                    }

                    .arrow-trend-up {
                        color: #A4032E;
                        transform: scaleX(-1) rotate(345deg);
                        padding-bottom: 10px;
                        padding-left: 10px;
                    }
                }
            }

        }
    }
}

@media screen and (min-width: 768px) {
#wb-locations-box {
    #wb-locations {
        background-color: white;
        position: relative;

        #box-location-list {
            overflow-y: auto;
            max-height: calc(70vh + 50px);
        }
    }
    #wb-map {
        padding-right: 10px !important;
    }
}
}