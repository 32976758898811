@import '../../styles/bootstrap-utilities';

#whereToBuyModal {
    --store-flex-basis: 28%;
    --store-container-gap: 1rem;
    @include media-breakpoint-up(md) {
        --store-flex-basis: 20%;
        --store-container-gap: 2rem;
    }
    transform: translate(-50%, 0%);
    z-index: 1000;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 90%;

    &.position-center {
        transform: translate(-50%, -50%);
        position: fixed;
        top: 50%;
        left: 50%;
        max-width: 900px;
    }
}

#containerWhereToBuyModal {
    padding: 1.5rem;
    background-color: white;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation: scaleX 0.5s 0.7s forwards ease;
    animation: scaleX 0.5s 0.2s forwards ease;
    -webkit-transform: scaleX(0);
    transform: scaleX(0) translate(-50%, 0%);
}
.store-container__img,
.img-fluid-custom {
    transition: all 0.3s ease-in;
    &:hover {
        transform: scale(1.15);
    }
}
.img-fluid-custom {
    width: 70%;
    margin: auto;
    display: flex;
    transition: all 0.3s ease-in;
}

.close-btn-modal {
    color: red;
    padding: 5px;
    position: absolute;
    right: 50px;
    top: 40px;
    transition: all 0.3s ease-in;
    display: block;
    border-radius: 100px;
    text-align: center;
}

.close-btn-modal:hover {
    transform: scale(1.3);
    cursor: pointer;
}
.store-container {
    gap: var(--store-container-gap);
    &__store {
        flex-basis: var(--store-flex-basis);
    }
    &__img {
        width: 100%;
    }
}
@include media-breakpoint-up(md) {
    #containerWhereToBuyModal {
        padding: 4rem;
    }

    .img-fluid-custom {
        width: 80%;
    }
}
@include media-breakpoint-up(lg) {
    #whereToBuyModal {
        width: 80%;
        /* &.position-center {
            #containerWhereToBuyModal {
                padding: 3rem;
            }
        } */
    }
    #containerWhereToBuyModal {
        padding: 5rem;
    }

    .img-fluid-custom {
        width: 80%;
    }
}
