/*BOTONES LOCATIONS*/

#wb-btns-box {
    position: relative;
}

#wb-form {
    z-index: 1010;
}

#wb-search {
    z-index: 1010;
    padding: 8px 20px;
    border-radius: 12px;
    background-color: white;
    border: none;
    outline: none;
    -webkit-filter: drop-shadow(3px 3px 3px rgba(75, 46, 33, 0.3));
    filter: drop-shadow(3px 3px 3px rgba(75, 46, 33, 0.3));
    transition: all ease-in 0.1s;
    font-size: 18px;
}

#wb-search:hover {
    transform: scale(1.05);
    color: var(--color-orange1);
}

#openModalButton{
    height: 40px;
    line-height: 60px;
}

#btn-secondary-custom {
    z-index: 1010;
    position: relative;
    background-color: var(--color-orange1);
    font-size: 28px;
    font-family: var(--font-gotham-bold);
    padding: 0px 30px;
    color: white;

    span {
        display: block;
        z-index: 1 !important;
    }
}

.modal-open .line-deco-btn {
    position: absolute;
    background-color: var(--color-feldspar);
    height: 3px;
    width: 30px;
    display: block;
    left: calc(50% - (16px));
    bottom: 3px;
    -webkit-animation: scaleX 0.5s 0.7s forwards ease;
    animation: scaleX 0.5s 0.7s forwards ease;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
}
.modal-open .wb__trackbar-box {
    display: block;
    z-index: -1;
    width: 35px;
    height: 35px;
    background-color: var(--color-orange1);
    border-radius: 30%;
    margin-bottom: -10px;
    margin-top: -25px;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-12px) rotate(45deg);
    animation: slideDown 0.5s 0.2s forwards ease;
}


#btn-secondary-custom:hover {
    color: var(--color-black);
}
#btn-secondary-custom.modal-open {
    color: var(--color-black);
}


#dondeComprarSection{
    background-image: url('../../assets/images/bg_full.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 0px;
    padding-top: 30px;

}

.brown, div.brown>div>div>h1{
    color: #40200C;
}

.uppercase{
    text-transform: uppercase;
}

.h3{
    font-size: 16px !important;
}


#wb-sec-2 {
    img {
        z-index: 1;
        max-width: 100%;
        height: auto;
    }

    .row {
        div:nth-of-type(1) {
            z-index: 1;
        }
    }

}

#wb-sec-2 h3 {

    display: flex;
    flex-direction: column;

    span:nth-of-type(1) {
        display: flex;
        margin-bottom: 10px;
        font-size: 100px;
        line-height: 70px;
    }

    span:nth-of-type(2) {
        font-size: 32px;
        background-color: var(--color-orange1);
        display: flex;
        width: 100%;
        max-width: 226px;
    }
}

@media screen and (min-width: 768px) {

    #dondeComprarSection{
        margin-top: -60px;
        padding-top: 100px;
    
    }

    .h3{
        font-size: 24px !important;
    }

    #wb-sec-2 {
        img {
            max-width: 340px;
            height: auto;
        }
    }

    #wb-sec-2 h3 {
        span:nth-of-type(1) {
            font-size: 150px;
            line-height: 120px;
        }
    
        span:nth-of-type(2) {
            font-size: 48px;
            display: flex;
            max-width: 408px;
        }
}
}

@media screen and (min-width: 1200px){
    #wb-sec-2 {
        img {
            max-width: 400px;
            height: auto;
        }
    }
    #wb-sec-2 h3 {
        span:nth-of-type(1) {
            font-size: 180px;
            line-height: 150px;
        }
    
        span:nth-of-type(2) {
            font-size: 60px;
            display: flex;
            max-width: 408px;
        }
    }
}
@media screen and (min-width: 1440px){
    #wb-sec-2 {
        img {
            max-width: 500px;
            height: auto;
        }
    }
}
