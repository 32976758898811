#mainFormContact{
    padding-top: 7rem !important;
}

.brown, div.brown>div>div>h1{
    color: #40200C;
}

.bernier
{
    font-family: "BERNIER Distressed";
}

.svgLineaCustomerCare
{
    filter: invert(52%) sepia(93%) saturate(628%) hue-rotate(348deg) brightness(92%) contrast(99%);
    width: 30%;
}

.uppercase{
    text-transform: uppercase;
}

.form-check-label{
    margin-left: .5rem;
}

.h2, h2.h2{
    font-size: 3.3rem;
}

p.gotham-hearfrom{
    font-family: var(--font-gotham);
}

div.col-12>.form-control, div.col-10>.form-control
{
    background: transparent;
    border: 1px solid #432e1e;
    border-radius: 2px;
    color: #40200C;
}

div.col-12>.form-control::placeholder, div.col-10>.form-control::placeholder
{
    color: #40200C;
    font-weight: 700;
}

div.form-col{
    padding: 0 .2rem;
}

label.form-check-label{
    color: #40200C;
    font-weight: 600;
}

#contactSection{
    background-image: url('../../assets/images/textura_fondo.webp');
    background-position: 0%;
    background-repeat: no-repeat;
    background-size: cover;
}

div.col-12>input.form-check-input:checked{
    background-color: #ea7f14;
    border: none;
    border-radius: 0;
}

div.col-12>input.form-check-input{
    border: 1px solid #432e1e;
    border-radius: 0;
    padding: .8rem;
}

.form-check-label{
    vertical-align: middle;
}

#sendContactBtn{
    background-color: #efa459;
    border: none;
    border-radius: 0;
    color: black;
    text-transform: uppercase;
    font-weight: 700;
    padding: .05rem 1rem;
}

#sendContactBtn:active, #sendContactBtn:focus{
    border: none;
}
.alx-link-telefono{
    display: block;
    width: 30%;
}
.alx-link-telefono img.svgLineaCustomerCare{
    width: 100%;
}

@media screen and (max-width: 767px) {
    .alx-link-telefono{
        width: 60%;
    }
    .alx-link-telefono img.svgLineaCustomerCare{
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    h2.main-contact-title
    {
        text-align: center;
    }

    img.svgLineaCustomerCare{
        width: 60%;
    }
    #contactSection {
        background-image: url('../../assets/images/textura_fondo.webp');
        background-position: center;
    }
    .alx-link-telefono{
        width: 80%;
    }
    .alx-link-telefono img.svgLineaCustomerCare{
        width: 100%;
    }
}

