#search-form {
    display: flex;
    position: relative;
    -webkit-filter: drop-shadow(3px 3px 3px rgba(75, 46, 33, 0.15));
    filter: drop-shadow(3px 3px 3px rgba(75, 46, 33, 0.15));

    #search-field {
        width: 100%;
        border: none;
        outline: none;
        border-radius: 100px;
        padding: 5px 50px 5px 20px;
    }

    #search-button {
        margin-left: -36px;
    }
}
